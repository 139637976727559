:root {
	--spacing: 1.2rem;
}

@media print {
	body {
		background-color: white !important;
	}
	.Sidebar {
		display: none;
	}
	.SidebarSpace {
		padding : 0 !important;
	}

	.recharts-wrapper,
	.recharts-surface,
	.recharts-legend-wrapper {
		width: 100% !important;
	}

	.session-unprintable {
		display: none !important;
	}
	.session-wrapper {
		gap: 10px !important;
		padding: 0px !important;
		box-shadow: none !important;
	}

}